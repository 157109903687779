import React from 'react';
import { Outlet, Link } from 'react-router-dom';

import Header from './components/Header';

export default function Layout () {
  
  return (
    <>
      <Header/>
      <Link to="/endPoints" />
      <Link to="/endpointDetails/:id" />
      <Link to="/dashboard" />
      <Link to="/notifications" />

      <Outlet/>
    </>
  );
}
