import React, { useState, useEffect } from "react";
import {
  Box,
  Alert,
  TextField,
  Link,
  Card,
  CardMedia,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Wallpaper from '../img/LogInTesatrack.jpg';
import Logo from '../img/blueLogo.png';

import userList from '../utils/userList';
import {LoginApi} from '../services/Login';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {'. Powered by '}
      <Link color="inherit" href="https://www.tesatelgps.com/">
        Tesatel GPS
      </Link>{'.'}
    </Typography>
  );
}

export default function SignIn() {
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => { localStorage.clear(); }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();
    let searchingUser = userList.find(user => user.name === userName);
    let errorText="";
    if(searchingUser && searchingUser.name === userName && searchingUser.password === password && searchingUser.status === "Active"){
      setLoading(true);
      let response = await LoginApi(userName);
      errorText = response;
    }
    else{ 
      try{
        if(searchingUser.password !== password ){
          errorText="User or password incorrect";
        }
        else if(searchingUser.status === "Suspended"){
          errorText="Account suspended, please make the corresponding payment.";
        }
      }
      catch(err){
        errorText="User or password incorrect";
      }
    }
    setError(errorText);
  };

  return (
    <>
      <img src={Wallpaper} id="img_fondo"/>

      <Card className="mx-auto containe_login">
        <CardMedia
          component="img"
          height="70"
          image={Logo}
        />
        
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            label="User"
            name="userName"
            value={userName}
            onChange={e => {
              setUserName(e.target.value);
            }}
            margin="dense"
            size="small"
            autoComplete="email"
            fullWidth
            required
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            margin="dense"
            size="small"
            autoComplete="current-password"
            fullWidth
            required
          />
          <LoadingButton
            sx={{ mt:2, mb:1 }}
            type="submit"
            loading={loading}
            disabled={userName && password !== ""? false:true}
            variant="contained"
            fullWidth
          >
            <span>Sing in</span>
          </LoadingButton>
        </Box>
        
        { error?
          <Alert severity="error" >
            {error}
          </Alert>
          : ''
        }
        
        <Copyright sx={{ mt: 4 }} />
      </Card>
    </>
  );
}