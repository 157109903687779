import React, {Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  DataGrid, 
  GridToolbarQuickFilter, 
  GridPagination,
  gridPageCountSelector, 
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import {
  Switch,
  FormControlLabel,
  Box,
  LinearProgress,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem
} from '@mui/material';
import { 
  PlayCircleOutline,
  StopCircleOutlined,
  GpsFixed,
  GpsNotFixed,
  Error,
  Warning,
  Podcasts,
  Webhook,
  Policy 
} from '@mui/icons-material';

import NoData from './NoData';
import EndpointActions from './EndpointActions'

function PerformPaging({page, onPageChange, className}){
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(any, newPage) => {
        onPageChange(any, newPage - 1);
      }}
    />
  );
}
function CustomPagination(props) {
  return <GridPagination ActionsComponent={PerformPaging} {...props} />;
}

export default function DataGridEndpoint(props){
  const navigate = useNavigate();
  
  function QuickSearchToolbar() {
    const handleChange = (event) => { props.filterChange(event.target.value); };

    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          m: 2
        }}
      >
        <FormControl variant="standard" size="small" sx={{ minWidth: 150, mr:2 }} disabled={props.isLoading} >
          <InputLabel>SIMs</InputLabel>
          <Select value={props.filterStatus} label="SIMs" onChange={handleChange}>
            <MenuItem value={'all'}><em>all</em></MenuItem>
            <MenuItem value={0}>Activated</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
          </Select>
        </FormControl>
        <GridToolbarQuickFilter sx={{ mt: 1.8 }} />
      </Box>
    );
  }

  const SimStatusChange = (data) => {
    const changeStatus=()=>{
      let newStatus=0;
      if(data.statusSIM.id === 1) newStatus = 2;
      else newStatus = 1;
      props.changeStatus(data.simID,newStatus, data.endpointID);
    }

    return (
      <FormControlLabel 
        control={
          <Switch 
            size="small" 
            disabled={props.isLoading}
            checked={data.statusSIM.id === 1? true:false} 
            onChange={changeStatus}
          />
        } 
        label={data.statusSIM.description}
      />
    );
  };
  const IconType = (data) => {
    switch (data.column) {
      case "severity":
        return (
          <Fragment>
            {data.text==="Info"?
            <Error sx={{ fontSize: 15, mr:1 }} color="action"/> :
            data.text==="Warn"?
            <Warning sx={{ fontSize: 15, mr:1, color: '#f57f17' }}/> 
            :
            <Warning sx={{ fontSize: 15, mr:1, color: '#f44336' }} /> 
            }
            {data.text}
          </Fragment>
        );
      case "eventSource":
        return (
          <Fragment>
            {data.text==="Network"?
            <Podcasts sx={{ fontSize: 15, mr:1 }} color="action"/> :
            data.text==="API"?
            <Webhook sx={{ fontSize: 15, mr:1 }} color="action"/> 
            :
            <Policy sx={{ fontSize: 15, mr:1 }} color="action"/> 
            }
            {data.text}
          </Fragment>
        );
      case "eventType":
        let text = data.text.substr(0,6);
        return (
          <Fragment>
             {text==="Create"?
            <PlayCircleOutline sx={{ fontSize: 15, mr:1, color: '#4caf50' }}/> :
            text==="Delete"?
            <StopCircleOutlined sx={{ fontSize: 15, mr:1, color: '#f44336' }}/> 
            : text==="Update"?
            <GpsFixed sx={{ fontSize: 15, mr:1 }} color="action"/> :
            text==="Purge "?
            <GpsNotFixed sx={{ fontSize: 15, mr:1 }} color="action"/> 
            : ''
            }
            {data.text}
          </Fragment>
        );
    }
  }

  const endpointsColumns = [
    { field: 'name', headerName: 'Name', minWidth: 400, flex: 1.5 },
    { 
      field: 'status',
      headerName: 'SIM', 
      minWidth: 120, flex: 0.5, sortable: false,
      renderCell: (params) => <SimStatusChange simID={params.row.simID} statusSIM={params.row.statusSIM} endpointID={params.row.id}/>,
    },
    { field: 'iccid', headerName: 'ICCID', minWidth: 175, flex: 0.5 },
    { field: 'msisdn', headerName: 'Phone number', minWidth: 150, flex: 0.5 },
    { field: 'imei', headerName: 'IMEI', minWidth: 150, flex: 0.5 } ,
    {
      field: 'details',
      headerName: '', 
      width: 180, sortable: false,
      renderCell: (params) => ( 
        <EndpointActions 
          rowData={params.row}
          updateData={props.updateData} 
          isLoading={props.isLoading}
          getListSMS={props.getListSMS}
        />
      ),
    }
  ];
  const eventsColumns = [
    { field: 'severity', headerName: 'Severity', minWidth: 100, flex: 1, sortable: false,
      renderCell: (params) => <IconType column="severity" text={params.row.severity}/>, },
    { field: 'date', headerName: 'Date', minWidth: 160, flex: 1, sortable: false },
    { field: 'eventSource', headerName: 'Event source', minWidth: 135, flex: 1, sortable: false,
      renderCell: (params) => <IconType column="eventSource" text={params.row.eventSource}/>,  },
    { field: 'eventType', headerName: 'Event type', minWidth: 200, flex: 1.5, sortable: false,
      renderCell: (params) => <IconType column="eventType" text={params.row.eventType}/>,  },
    { field: 'endpointName', headerName: 'SIM', minWidth: 250, flex: 2, sortable: false,
      renderCell: (params) => 
        <Button onClick={() => {navigate('/simDetails/'+ params.row.endpointID, {state:{simID: params.row.simID}})}}>
          {params.row.endpointName}
        </Button>,  
    },
    { field: 'network', headerName: 'Network', minWidth: 200, flex: 1.5, sortable: false },
    { field: 'country', headerName: 'Country', minWidth: 100, flex: 1, sortable: false },
  ];

  if(props.table === "events"){
    eventsColumns.splice(4, 1);
  }

  return (
    <Box sx={{ height: props.height, }}>
      {props.table === "endpoints"?
        <DataGrid
          rows={props.row}
          columns={endpointsColumns}
          rowHeight={45}
          loading={props.isLoading}
          slots={{ 
            loadingOverlay: LinearProgress,
            toolbar: QuickSearchToolbar, 
            noRowsOverlay: NoData, 
            pagination: CustomPagination,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableColumnMenu 
          disableRowSelectionOnClick
        />
      :
        <DataGrid
          rows={props.row}
          columns={eventsColumns}
          rowHeight={45}
          loading={props.row.length > 0? false:true}
          slots={{ 
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoData, 
            pagination: CustomPagination,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableColumnMenu 
          disableRowSelectionOnClick
        />
      }
    </Box>
  );
}