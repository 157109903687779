import React from 'react';
import {Container, 
    LinearProgress,
  Typography,
  Divider
} from '@mui/material';
import { PieChart,pieArcLabelClasses} from '@mui/x-charts/PieChart';
import {ArrowDropDown,ArrowDropUp} from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';


export default function Details() {
    let consumedData = localStorage.getItem("consumedData"),
    sims = localStorage.getItem("sims");

    const converMBtoGB = (total) => {
        let newTotal;

        if(total < 1024){
            newTotal = total.toFixed(2) + " MB"
        }
        else{
            total = total/1024;
            newTotal = total.toFixed(2) + " GB"
        }

        return newTotal;
    }

    const getArcLabel = (params) => {
        let TOTAL= JSON.parse(sims)[0].value + JSON.parse(sims)[1].value
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

  return (
    <Container maxWidth={false} className="containerPage">
        
        {JSON.parse(consumedData).data === undefined?
        <Grid container spacing={4} display="flex" justifyContent="center">
            <Grid xs={12} md={5} >
                <Divider textAlign="left">Current Month Data Volume</Divider>
                <Typography variant="h4" sx={{ textAlign: 'center', pt: '10%'}}>
                    {converMBtoGB(JSON.parse(consumedData).total)}
                </Typography>
                
                <Typography variant="subtitle1" sx={{ textAlign: 'center'}} >
                    <ArrowDropUp sx={{ color: '#02b2af' }} />
                    {converMBtoGB(JSON.parse(consumedData).MBup)} 
                    <ArrowDropDown sx={{ color: '#2e96ff' }} />
                    {converMBtoGB(JSON.parse(consumedData).MBdown)}
                </Typography>

                <Typography variant="h6" sx={{ textAlign: 'center'}}>
                    {"("+ converMBtoGB(JSON.parse(sims)[0].value * 10) +" included)"}
                </Typography>
                
            </Grid>
            <Grid xs={12} md={7}>
                <Divider textAlign="left">SIMs Status</Divider>
                <PieChart
                series={[
                    {
                    data:JSON.parse(sims),
                    paddingAngle: 2,
                    innerRadius: 70,
                    outerRadius: 120,
                    arcLabel: getArcLabel,
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontSize: 14,
                    },
                }}
                height={250}
                />
            </Grid>
        </Grid>
        : 
        <LinearProgress/>
        }
    </Container>
  );
}