import React, {Fragment, useState} from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Typography
} from '@mui/material';
import { BarChart } from '@mui/x-charts';


export default function Chart(props) {
  const [month, setMonth] = useState("current");
  const [chart, setChart ] = useState(["data","MBup","MBup","MBdown","MBdown"]);
  
  const valueFormatter = (value) => {
    if(chart[0] === "data") return `${value}MB`
    else return `${value}SMS`
  };

  const handleChange = async(e) => { 
    await props.updateGraph(e.target.value); 
    setMonth(e.target.value);
  };
  const graphicChange = (e, newValue) => {
    let graphData = ["data","MBup","MBup","MBdown","MBdown"];
    if(newValue === "sms"){
      graphData = ["sms","SMSmt","SMS MT","SMSmo","SMS MO"];
    }
    setChart(graphData);
  };

  return (
    <Fragment>
      <FormControl size="small" sx={{ minWidth: 150, mx:2, float: 'inline-end' }} >
        <Select value={month} inputProps={{ 'aria-label': 'Without label' }} onChange={handleChange}>
          <MenuItem defaultValue value="current">Current month</MenuItem>
          <MenuItem value="last">Last month</MenuItem>
        </Select>
      </FormControl>
      <Tabs value={chart[0]} onChange={graphicChange} sx={{ float: 'inline-end' }}>
        <Tab label="Data" value="data" />
        <Tab label="SMS" value="sms" />
      </Tabs>

      <Box sx={{ width: 1, display: 'inline-block' }}>
        {props.dataUsage.totals?.totalMB > 0?
          <Fragment>
            <Typography variant="body2" sx={{ float: 'inline-end' }}>
              {chart[0] === "data"?
              "Data total: " + props.dataUsage.totals?.totalMB  + " " + props.dataUsage.totals?.MBup + " " + chart[2] + " " + props.dataUsage.totals?.MBdown + " " + chart[4] 
              :
              "SMS total: " + props.dataUsage.totals?.totalSMS + " " + props.dataUsage.totals?.SMSmt + "  " + chart[2] + " " + props.dataUsage.totals?.SMSmo + " " + chart[4]
              }
            </Typography>

            <BarChart
              height={300}
              dataset={props.dataUsage?.data}
              xAxis={[{ scaleType: 'band', dataKey: 'day', label: 'Days of month' }]}
              series={[
                { dataKey: chart[1], label: chart[2], valueFormatter },
                { dataKey: chart[3], label: chart[4], valueFormatter },
              ]}
            />
          </Fragment>
          :
          <Typography align='center' variant="subtitle2" sx={{ m: '10%' }}>
            No usage within selected time period
          </Typography>
        }
      </Box>
    </Fragment>
  );
}