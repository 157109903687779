import React from 'react';
import {Collapse,Alert,IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Alerts(props) {
  
    return (
      <Collapse in={props.alertDetails.open} className="alerts">
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.setAlertDetails(props.alertDetails.open=false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mt: 1, mr: 3, position:'fixed', right: 0 }}
          severity="success"
        >
          {props.alertDetails.text}
        </Alert>
      </Collapse>
    );
  }
  