import React from "react";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import Layout from '../Layout';

import Login from '../views/Login';
import Dashboard from '../views/Dashboard';
import EndPoints from '../views/EndPoints';
import Notifications from '../views/Notifications';
import EndpointDetails from '../views/EndpointDetails';

export default function RouteList() {
    return (
        <Router >
            <Routes>
                <Route exact path="/" element={<Login />} /> 
                <Route path="/" element={<Layout />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="mySims" element={<EndPoints />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route exact path="simDetails/:id" element={<EndpointDetails />} />
                </Route>
            </Routes>
        </Router>
    )
}