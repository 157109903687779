import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import {UpdateEndpoint} from '../services/Endpoints';

export default function Popups(props) {
  /* performs line reset with status change (0=Enabled 1=Disabled).., 
  updates the list of endpoints and enables notification of successful reset.
  Parameters:
    -endpointID: id of the endpoint to edit.
    -name: name of the endpoint */
  const resetConnectivity = async() => {
    //Disabled endpoint
    await UpdateEndpoint(props.endpointID, ["status", 1]);
    //Enabled endpoint 
    await UpdateEndpoint(props.endpointID, ["status", 0]);
    props.updateData("Connectivity has been successfully restarted");
    props.handleClose();
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Reset device connectivity?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        This action will cause the mobile network to perform a new connection procedure, so it will take a few minutes for connectivity to be restored.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={()=>resetConnectivity()} variant="contained">Reset</Button>
      </DialogActions>
    </Dialog>
  );
}
